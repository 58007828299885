import React, { useCallback, useEffect, useState } from 'react';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';

import { styled } from 'styles';
import { useTrackEvents } from 'modules/mvp/hooks';

export function CheckedSearchResult({ question }) {
  const [showResolution, setShowResolution] = useState(false);
  const { trackViewQuestion } = useTrackEvents();

  useEffect(() => {
    if (!question) return;

    trackViewQuestion(question, question.mvpListWithStatement);
  }, [question, trackViewQuestion]);

  const toggleShowResolution = useCallback(() => {
    setShowResolution(!showResolution);
  }, [showResolution]);

  const showAnswerButtonCopy = useCallback(() => {
    if (question.exerciseType === 'MvpExercise')
      return showResolution
        ? 'Esconder resolução da IA'
        : 'Ver resolução da IA';

    return showResolution ? 'Esconder resolução' : 'Ver resolução';
  }, [question, showResolution]);

  return (
    <Container>
      <StatementTitle>Questão {question.label} da Lista</StatementTitle>
      <Statement text={question.exercise.lightBody} />
      <ShowAnserButton onClick={toggleShowResolution}>
        {showAnswerButtonCopy()}
      </ShowAnserButton>
      {question.exercise.lightSolution.map((solution, index) => (
        <Step key={index} className={showResolution ? 'show' : 'hide'}>
          <StepTitle>Passo {index + 1}</StepTitle>
          <Solution text={solution} />
        </Step>
      ))}

      {question.exercise.lightAnswer && (
        <>
          <AnswerTitle className={showResolution ? 'show' : 'hide'}>
            Resposta
          </AnswerTitle>
          <AnswerContent className={showResolution ? 'show' : 'hide'}>
            <Answer text={question.exercise.lightAnswer} />
          </AnswerContent>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
`;

const StatementTitle = styled.div`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.6em;
  text-decoration: underline;
`;

const Statement = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 15px;
`;

const ShowAnserButton = styled.button`
  width: 280px;
  max-width: 95%;
  padding: 15px 0;
  margin: 10px auto 0 auto;
  background-color: #f7ac3d;
  color: #fff;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 40px;
`;

const Step = styled.div`
  margin-bottom: 30px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const StepTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 40px;
  }
`;

const Solution = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 10px;
`;

const AnswerTitle = styled.h1`
  width: 100%;
  color: #f7ac3d;
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const AnswerContent = styled.div`
  border-left: 4px solid #f7ac3d;
  padding: 20px;
  font-size: 1.2em;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

const Answer = styled(MathjaxContentWithLatex)`
  margin: 30px 0px;
`;
