import { useTracker } from 'modules/shared/hooks';
import { useDialogContext } from 'providers/DialogProvider';
import React, { useCallback, useEffect, useMemo } from 'react';
import { styled } from 'styles';
import { UploadBookListDialog } from '../dialogs/UploadBookListDialog';

export function MvpThemesListBannerMobile({ origin }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const eventProperties = useMemo(() => {
    return {
      Onde: origin,
      Plataforma: 'Mobile',
      Versão: '2.1',
    };
  }, [origin]);

  const onClickBanner = useCallback(() => {
    trackEvent('MVP Lista: Clicou Banner', eventProperties);

    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent: 'pag_home',
    };

    setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
  }, [setDialog, trackEvent, eventProperties, origin]);

  useEffect(() => {
    trackEvent('MVP Lista: Visualizou Banner', eventProperties);
  }, [trackEvent, eventProperties]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <h2>Resolvemos a sua lista em 45 segundos!</h2>
      <CTAButton>Quero a minha lista resolvida</CTAButton>
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  width: 100%;
  height: 60px;
  background: #06a54f;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;

  h2 {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    max-width: 90%;
  }
`;

const CTAButton = styled.button`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  outline: 0;
  border: 0;
  text-transform: uppercase;
  color: #0f6a39;
  font-weight: bold;
  font-size: 10px;
  padding: 0 20px;
`;
