import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled, media } from 'styles';
import { IoIosStar } from 'react-icons/io';
import ProfessorsContainer from './ProfessorsContainer';
import ListsContainer from './ListsContainer';
import { useProfessorsLists } from 'modules/books/hooks/useProfessorsLists';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';

export default function ProfessorsLists() {
  const {
    getOneProfessorFromUserProfessors,
    getProfessorsWhoHaveListsThatHaveTheSameExercise,
  } = useProfessorsLists();
  const {
    professors,
    userProfessors,
    loadingProfessors,
    selectedProfessor,
    setSelectedProfessor,
  } = useBookEditionContext();
  const [openingType, setOpeningType] = useState(null);

  const setInitialProfessor = useCallback(() => {
    if (!professors) {
      setOpeningType(null);
      return setSelectedProfessor(null);
    }

    if (professors.length === 1) {
      setOpeningType('Apenas 1 professor');
      return setSelectedProfessor(professors[0]);
    }

    const anyUserProfessor = getOneProfessorFromUserProfessors(
      professors,
      userProfessors
    );

    if (anyUserProfessor) {
      setOpeningType('Professor do semestre');
      return setSelectedProfessor(anyUserProfessor);
    }

    const professorsWhoHaveListsThatHaveTheSameExercise = getProfessorsWhoHaveListsThatHaveTheSameExercise(
      professors
    );

    if (professorsWhoHaveListsThatHaveTheSameExercise.length === 1)
      setOpeningType('Apenas 1 professor com a questão');
    return setSelectedProfessor(
      professorsWhoHaveListsThatHaveTheSameExercise[0]
    );
  }, [
    getOneProfessorFromUserProfessors,
    getProfessorsWhoHaveListsThatHaveTheSameExercise,
    professors,
    setSelectedProfessor,
    userProfessors,
  ]);

  const shouldShowListsContainer = useMemo(() => {
    if (!loadingProfessors && professors?.length === 0) return false;

    return selectedProfessor || media.isDesktop();
  }, [loadingProfessors, professors, selectedProfessor]);

  const origin = useMemo(() => 'Area Listas em Livros', []);

  useEffect(() => {
    setInitialProfessor();
  }, [setInitialProfessor]);

  return (
    <Container>
      <Tag>
        <p>Até 3 exercícios GRÁTIS</p>
        <IoIosStar size="18px" />
      </Tag>
      <>
        <ProfessorsContainer
          selectedProfessor={selectedProfessor}
          setSelectedProfessor={setSelectedProfessor}
          origin={origin}
          setOpeningType={setOpeningType}
        />

        {shouldShowListsContainer && (
          <>
            {media.isDesktop() && <VerticalLine />}
            <ListsContainer
              selectedProfessor={selectedProfessor}
              origin={origin}
              openingType={openingType}
            />
          </>
        )}
      </>
    </Container>
  );
}

const Container = styled.div`
  width: 960px;
  max-width: 95%;
  margin: -50px auto 30px auto;
  padding: 30px 25px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;

  display: flex;
  align-items: center;

  @media ${media.mobile} {
    margin-top: 20px;
    padding: 20px 10px;
    flex-direction: column;
  }
`;

const Tag = styled.div`
  position: absolute;
  top: -15px;
  left: 23px;
  width: 270px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: #f7ac3d;
  border-radius: 7px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;

  p {
    margin-right: 5px;
  }
`;

const VerticalLine = styled.div`
  width: 2px;
  height: 230px;
  background-color: #d9d9d9;
`;
