import React, { useCallback, useMemo } from 'react';
import UniversityPersonalizedContentBanner from './banners/UniversityPersonalizedContentBanner';

import { MobileBannerMaker } from 'modules/banner/shared/banners';
import { styled } from 'styles';
import { useBannerContext } from 'providers/BannerProvider';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';

function MobileBookBanner() {
  const { bannersToShowInfo } = useBannerContext();
  const { loadingProfessors, professors } = useBookEditionContext();
  const {
    isBannerEligibleInBookProfessorsListsSection,
  } = useMvpBooksEligible();

  const shouldShowMvpListBanner = useMemo(() => {
    return (
      professors &&
      professors.length > 0 &&
      isBannerEligibleInBookProfessorsListsSection()
    );
  }, [isBannerEligibleInBookProfessorsListsSection, professors]);

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.mobileFrontBannerName === 'mobileBannerMaker')
      return <MobileBannerMaker source="Book" />;
    if (loadingProfessors) return null;
    if (shouldShowMvpListBanner)
      return (
        <MvpBookExerciseListsBanner
          origin="Exercicios de Livro"
          style={{ marginTop: '0px' }}
        />
      );
    if (bannersToShowInfo?.bookBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
  }, [bannersToShowInfo, loadingProfessors, shouldShowMvpListBanner]);

  return getPriorityBanner() ? (
    <Container>{getPriorityBanner()}</Container>
  ) : (
    <></>
  );
}

const Container = styled.div`
  width: 98%;
  border-radius: 8px;
  height: 70px;
  z-index: 100;
  cursor: pointer;
  overflow: hidden;
`;

export default MobileBookBanner;
