import React, { useEffect, useCallback, useMemo } from 'react';
import { media, styled } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useTracker } from 'modules/shared/hooks';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';

export default function MvpBooksBanner({ origin }) {
  const { setDialog } = useDialogContext();
  const { trackEvent } = useTracker();

  const eventProperties = useMemo(() => {
    return {
      Onde: origin,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '2.1',
    };
  }, [origin]);

  const onClickBanner = useCallback(() => {
    trackEvent('MVP Lista: Clicou Banner', eventProperties);

    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'banner',
      utmContent: 'pag_edicoes',
    };

    setDialog(<UploadBookListDialog origin={origin} utms={utms} />);
  }, [setDialog, trackEvent, eventProperties, origin]);

  useEffect(() => {
    trackEvent('MVP Lista: Visualizou Banner', eventProperties);
  }, [trackEvent, eventProperties]);

  return (
    <ContainerBanner onClick={onClickBanner}>
      <ContentBanner>
        <BannerTitle>
          Resolvemos a sua lista de Exercícios
          <br /> em 45 segundos
        </BannerTitle>
        <BannerButton>Quero minha lista resolvida</BannerButton>
      </ContentBanner>
      <RightTriangle />
      <BottomBase />
      <LeftTriangle />
    </ContainerBanner>
  );
}

const ContainerBanner = styled.div`
  position: relative;
  width: 780px;
  max-width: 90%;
  height: 110px;
  margin-left: 35px;
  cursor: pointer;

  @media ${media.mobile} {
    margin-left: 10px;
    height: 80px;
  }
`;

const ContentBanner = styled.div`
  position: relative;
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #06a54f;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px auto 0 auto;
  border-radius: 6px;
  cursor: pointer;

  @media ${media.mobile} {
    height: 65px;
  }
`;

const RightTriangle = styled.div`
  position: absolute;
  top: 0px;
  right: -105px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 110px 0 0 110px;
  border-color: transparent transparent transparent #0f6a39;

  @media ${media.mobile} {
    top: 2px;
    right: -13px;
    border-width: 78px 0 0 14px;
  }
`;

const BottomBase = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: #0f6a39;

  @media ${media.mobile} {
  }
`;

const LeftTriangle = styled.div`
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 0 0 30px;
  border-color: transparent transparent transparent #f9f9f9;

  @media ${media.mobile} {
    left: -10px;
  }
`;

const BannerTitle = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
  margin-left: 20px;
  text-align: center;

  @media ${media.mobile} {
    font-size: 0.69rem;
    line-height: 15px;
    margin-left: 7px;
    flex-grow: 1;
  }
`;

const BannerButton = styled.div`
  width: 350px;
  height: 50px;
  background-color: #ffffff;
  color: #0f6a39;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  font-size: 1.2rem;
  font-weight: bold;

  @media ${media.mobile} {
    width: 120px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;

    font-size: 0.55rem;
  }
`;
