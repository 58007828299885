import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useTrackEvents() {
  const { getUTMsByLocation } = useUTM();
  const { trackEvent } = useTracker();

  const utms = useMemo(() => {
    return getUTMsByLocation(window.location);
  }, [getUTMsByLocation]);

  const defaultProperties = useMemo(() => {
    return {
      ...utms,
      Versão: 1,
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
    };
  }, [utms]);

  const getListProps = useCallback(list => {
    return {
      'ID da Lista': list.id,
      Professor: list.professor?.name,
      Disciplina: list.discipline?.name,
      Exame: list?.exam,
      'Quantidade Questoes': list.questions?.length,
      'Status da Lista': list.status,
    };
  }, []);

  const trackViewListPage = useCallback(
    (list, solvedPercentage) => {
      trackEvent('Lista de Enunciado: Visualizou página de lista', {
        ...getListProps(list),
        '% Resolvida': solvedPercentage,
        ...defaultProperties,
      });
    },
    [trackEvent, getListProps, defaultProperties]
  );

  const getQuestionProps = useCallback(question => {
    return {
      'ID da Questao': question.id,
      'Label da Questao': question.label,
      'Tipo Exercicio': question.exerciseType,
      'ID Exercicio': question.exerciseId,
    };
  }, []);

  const trackViewCheckSearchResult = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou tela de validar resultado', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackCheckResultSearch = useCallback(
    (question, found, list) => {
      trackEvent('Lista de Enunciado: Validou resultado', {
        ...getQuestionProps(question),
        ...getListProps(list),
        'Questao Certa?': found ? 'Sim' : 'Nao',
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackViewQuestion = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou pagina da resolução', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackViewSearchOptions = useCallback(
    (question, list) => {
      trackEvent('Lista de Enunciado: Visualizou possibilidades da busca', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackSelectSearchOptions = useCallback(
    (question, list, selectedLog) => {
      trackEvent('Lista de Enunciado: Selecionou possibilidade da busca', {
        ...getQuestionProps(question),
        ...getListProps(list),
        ...defaultProperties,
        'Tipo Exercicio': selectedLog.exerciseType,
        'ID Exercicio': selectedLog.exerciseId,
      });
    },
    [trackEvent, getQuestionProps, getListProps, defaultProperties]
  );

  const trackClickFavoriteFeature = useCallback(
    ({ isFavorite, listId, origin }) => {
      trackEvent('Lista de Enunciado: Clicou Favoritar', {
        'ID da Lista': listId,
        ...defaultProperties,
        Favorito: isFavorite ? 'Sim' : 'Nao',
        Local: origin,
      });
    },
    [trackEvent, defaultProperties]
  );

  return {
    trackViewListPage,
    trackViewCheckSearchResult,
    trackCheckResultSearch,
    trackViewQuestion,
    trackViewSearchOptions,
    trackSelectSearchOptions,
    trackClickFavoriteFeature,
  };
}
