import { useCallback } from 'react';

export default function useQuestions() {
  const solvedPercentage = useCallback(list => {
    if (!list || !list.questions) return 0;

    const total = list.questions.length;
    const solved = list.questions.filter(
      question => question.status === 'found'
    ).length;

    return parseInt((solved / total) * 100);
  }, []);

  const getNextExercise = useCallback((list, currentExerciseId) => {
    if (!list.questions) return null;
    const exercisesIds = list.questions.map(question => question.id);
    const currentExerciseIndex = exercisesIds.indexOf(currentExerciseId);

    if (list.questions[currentExerciseIndex + 1])
      return list.questions[currentExerciseIndex + 1];

    return null;
  }, []);

  const getPreviousExercise = useCallback((list, currentExerciseId) => {
    if (!list.questions) return null;
    const exercisesIds = list.questions.map(question => question.id);
    const currentExerciseIndex = exercisesIds.indexOf(currentExerciseId);

    if (list.questions[currentExerciseIndex - 1])
      return list.questions[currentExerciseIndex - 1];

    return null;
  }, []);

  const questionsWithBlocked = useCallback(
    list => {
      const { questions } = list;
      if (!questions) return [];

      if (solvedPercentage(list) <= 50) {
        return questions.map(question => {
          return {
            ...question,
            blocked: false,
          };
        });
      }

      const questionsWithBlockStatus = questions.map(question => {
        return {
          ...question,
          blocked: question.status === 'found' ? true : false,
        };
      });

      const foundQuestionIndexes = questions.reduce((acc, question, i) => {
        if (question.status === 'found') return [...acc, i];
        return acc;
      }, []);

      const solvedQuestionsCount = foundQuestionIndexes.length;

      let numberOfFreeQuestions = Math.round(solvedQuestionsCount * 0.2);

      if (numberOfFreeQuestions > 6) numberOfFreeQuestions = 6;

      if (numberOfFreeQuestions < 2) numberOfFreeQuestions = 2;

      if (
        foundQuestionIndexes[0] !== null &&
        foundQuestionIndexes[0] !== undefined &&
        questionsWithBlockStatus[foundQuestionIndexes[0]]
      ) {
        questionsWithBlockStatus[foundQuestionIndexes[0]].blocked = false;
      }
      if (
        foundQuestionIndexes[1] &&
        questionsWithBlockStatus[foundQuestionIndexes[1]]
      ) {
        questionsWithBlockStatus[foundQuestionIndexes[1]].blocked = false;
      }

      const remainingFreeQuestions = numberOfFreeQuestions - 2;

      let intervalBetweenFreeQuestions = Math.floor(
        (Math.floor(solvedQuestionsCount / 2) - 2) / remainingFreeQuestions
      );

      if (intervalBetweenFreeQuestions < 1) intervalBetweenFreeQuestions = 1;

      for (let i = 0; i < remainingFreeQuestions; i++) {
        const index = (i + 2) * intervalBetweenFreeQuestions;
        if (index < foundQuestionIndexes.length) {
          questionsWithBlockStatus[foundQuestionIndexes[index]].blocked = false;
        }
      }
      return questionsWithBlockStatus;
    },
    [solvedPercentage]
  );

  return {
    questionsWithBlocked,
    solvedPercentage,
    getPreviousExercise,
    getNextExercise,
  };
}
