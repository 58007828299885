import React, { useCallback, useEffect } from 'react';
import { styled } from 'styles';
import { useProfessorTracker } from 'modules/guides/hooks';
import { IoIosStarOutline, IoIosStar } from 'react-icons/io';
import useFavoriteList from 'modules/mvp/hooks/useFavoriteList';
import { useTooltipContext } from 'providers/TooltipProvider';

export default function ListLink({
  list,
  professor,
  name,
  mutateProfessorQuery,
  fontSize = 18,
  utmContent,
}) {
  const { trackClickList } = useProfessorTracker();
  const {
    postListNewStatus,
    isFavoriteState,
    setIsFavoriteState,
    isListFavoriteFromBackend,
  } = useFavoriteList();

  const {
    isShowing,
    previewPosition,
    showPreview,
    stopPreview,
    activeListId,
  } = useTooltipContext();

  const handleMouseEnter = event => {
    showPreview(event.target, 300, 200, list.id);
  };

  const handleMouseLeave = () => {
    stopPreview();
  };

  const handleListClick = useCallback(
    list => {
      trackClickList({
        list: list,
        professorName: professor.name,
        disciplineName: professor.teachable.name,
        listType: list.type === 'statementList' ? 'Enunciado' : 'Livro',
      });

      const utmSource = 'site_navegacao';
      const utmMedium = 'botao_lista';

      if (list.type === 'bookList') {
        window.open(
          `${process.env.REACT_APP_MVP_URL}/listas/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`,
          '_blank'
        );
      } else {
        window.open(
          `${process.env.REACT_APP_REACT_URL}/lista-de-enunciado/${list.id}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`,
          '_blank'
        );
      }
    },
    [professor, trackClickList, utmContent]
  );

  const onStarClick = useCallback(
    async (event, isFavorite) => {
      event.stopPropagation();

      await postListNewStatus(
        {
          listId: list.id,
          listType:
            list.type === 'statementList'
              ? 'MvpListWithStatement'
              : 'MvpBookExerciseList',
          isFavorite,
          origin: 'Pagina do professor',
        },
        mutateProfessorQuery
      );
    },
    [list.id, list.type, postListNewStatus, mutateProfessorQuery]
  );

  useEffect(() => {
    setIsFavoriteState(isListFavoriteFromBackend(list));
  }, [isListFavoriteFromBackend, list, setIsFavoriteState]);

  if (!list) return null;

  console.log(list.previewImageUrl);

  return (
    <Container>
      <ListLinkStyled
        onMouseEnter={e => handleMouseEnter(e)}
        onMouseLeave={e => handleMouseLeave(e)}
        onClick={() => handleListClick(list)}
        key={list.id}
        isFavorite={isFavoriteState}
      >
        {isFavoriteState ? (
          <IoIosStar className="icon" onClick={e => onStarClick(e, false)} />
        ) : (
          <IoIosStarOutline
            className="icon"
            onClick={e => onStarClick(e, true)}
          />
        )}
        <p style={{ fontSize: `${fontSize}px` }}>{name}</p>
      </ListLinkStyled>

      {isShowing && activeListId === list.id && (
        <Preview className="tooltip" position={previewPosition}>
          <img src={list.previewImageUrl} alt={`Preview da lista`} />
        </Preview>
      )}
    </Container>
  );
}

const Preview = styled.div`
  z-index: 9999;
  position: fixed;
  top: ${props => {
    return `${props.position.y}px`;
  }};
  left: ${props => {
    return `${props.position.x}px`;
  }};

  width: 200px; /* Largura da pré-visualização */
  height: 300px; /* Altura da pré-visualização */
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 1;
  img {
    z-index: 9999;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Container = styled.div``;

const ListLinkStyled = styled.div`
  background-color: #f6f6f6;
  border-radius: 10px;
  color: ${({ isFavorite }) => (isFavorite ? '#f7ac3d' : '#515151')};
  font-weight: ${({ isFavorite }) => isFavorite && 'bold'};
  margin: 5px 10px;
  padding: 10px 13px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    font-size: 27px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
      scale: 1.3;
    }
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;
