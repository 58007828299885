import { useUserContext } from 'providers/UserProvider';
import { useCallback } from 'react';

export function useSolveQuestionWithAi() {
  const { user } = useUserContext();

  const isQuestionEligible = useCallback(
    question => {
      if (question.status !== 'notFound') return false;
      if (question.images.length > 0) return false;
      if (!user) return false;

      return user.email.includes('@respondeai');
    },
    [user]
  );

  return { isQuestionEligible };
}
