import React, { useMemo } from 'react';
import { css } from 'styled-components';

import { media, styled } from 'styles';
import { Link } from 'react-router-dom';
import { FaFaceFrown, FaWandMagicSparkles } from 'react-icons/fa6';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import { useUserContext } from 'providers/UserProvider';
import lock from '../../../../images/mvp/lock.svg';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { useDialogContext } from 'providers/DialogProvider';
import { useSolveQuestionWithAi } from 'modules/mvp/hooks/useSolveQuestionWithAi';

export function Question({ question, listId, isUserOwner }) {
  const topic = question.exercise?.topic;
  const { user } = useUserContext();
  const {
    isQuestionEligible: isQuestionEligibleToBeSolvedByAi,
  } = useSolveQuestionWithAi();

  const { setDialog } = useDialogContext();

  const isQuestionBlocked = useMemo(() => {
    if (!question.blocked) return false;
    return !user?.hasAccess;
  }, [question.blocked, user?.hasAccess]);

  return (
    <Container>
      <Line>
        {topic && (
          <>
            <Topic>
              <b>Tópico:</b> {topic.name} |{' '}
              <a
                href={`/aprender/topico/${topic.subjectId}/${topic.id}/teoria/${topic.theory?.id}`}
                target="_blank"
              >
                Revisar Tópico
              </a>{' '}
            </Topic>
            <QuestionFoundInfo question={question} />
          </>
        )}
        {!topic && (
          <>
            <QuestionLabel>Questão {question.label}</QuestionLabel>
            <QuestionFoundInfo question={question} />
          </>
        )}
      </Line>
      {topic && (
        <Line>
          <QuestionLabel>Questão {question.label}</QuestionLabel>
        </Line>
      )}
      <Line style={{ marginTop: '10px' }}>
        <Statement text={question.statement} isLatex={true} />
        {question.images.length > 0 && <Image src={question.images[0]} />}
      </Line>

      {question.status === 'notFound' && (
        <Line style={{ marginTop: '10px', marginBottom: '5px' }}>
          <NotFoundMessage>
            Não encontramos sua questão{' '}
            <FaFaceFrown style={{ marginLeft: '3px' }} />
          </NotFoundMessage>
        </Line>
      )}

      <Line
        style={{
          marginTop: question.status === 'found' ? '10px' : '0',
          justifyContent: 'center',
        }}
      >
        {isQuestionBlocked ? (
          <OpenDialog
            className={question.status === 'found' ? 'fill' : ''}
            onClick={() =>
              setDialog(<PlansDialog source={'Lista de Enunciado'} />)
            }
          >
            {question.status === 'found'
              ? 'Ver resolução'
              : 'Quero ver questões parecidas'}
            <img src={lock} alt="Cadeado" />
          </OpenDialog>
        ) : (
          <>
            <OpenLink
              to={`/lista-de-enunciado/${listId}/questao/${question.id}`}
              className={question.status === 'found' ? 'fill' : ''}
            >
              {question.status === 'found'
                ? 'Ver resolução'
                : 'Quero ver questões parecidas'}
            </OpenLink>
            {isQuestionEligibleToBeSolvedByAi(question) && isUserOwner && (
              <AiSolveLink
                to={`/lista-de-enunciado/${listId}/questao/${question.id}/resolver-com-ia`}
                style={{ marginLeft: '10px' }}
              >
                <FaWandMagicSparkles style={{ marginRight: '10px' }} />
                Gerar resolução com IA
              </AiSolveLink>
            )}
          </>
        )}
      </Line>
    </Container>
  );
}

function QuestionFoundInfo({ question }) {
  if (question.status === 'found' && question.exerciseType === 'MvpExercise')
    return (
      <QuestionTag color="#6a0d91" style={{ width: '180px' }}>
        Resolvida por IA
      </QuestionTag>
    );

  if (question.status === 'found')
    return <QuestionTag color="#38a725">Resolvida</QuestionTag>;

  return <QuestionTag color="#d0cec8">Nao Resolvida</QuestionTag>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 40px;
  background-color: #ebe8e8;
  border-radius: 15px;

  @media ${media.mobile} {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
  }
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${media.mobile} {
    flex-direction: column;
    margin-top: 0 !important;
  }
`;

const Topic = styled.p`
  font-size: 1.1em;
  a {
    text-decoration: underline;
    color: #f7ac3d;
    cursor: pointer;
  }
`;

const QuestionTag = styled.div`
  width: 150px;
  text-align: center;
  padding: 10px 0;
  border-radius: 13px;
  background-color: ${({ color }) => color};
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;

  @media ${media.mobile} {
    margin-top: 10px;
    font-size: 1em;
  }
`;

const Statement = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
`;

const QuestionLabel = styled.h1`
  font-size: 1.8em;
  font-weight: bold;
  color: #5d5d5d;

  @media ${media.mobile} {
    margin-top: 10px;
  }
`;

const Image = styled.img`
  max-width: 150px;
  height: auto;
  object-fit: contain;
  margin-left: 20px;

  @media ${media.mobile} {
    margin: 0 auto 10px auto;
  }
`;

const NotFoundMessage = styled.p`
  display: flex;
  align-items: center;
  margin: auto;
  color: #5d5d5d;
  font-weight: bold;
`;

const commonStyles = css`
  padding: 10px 40px;
  border: 1px solid #f7ac3d;
  font-size: 1.2em;
  font-weight: bold;
  color: #f7ac3d;
  border-radius: 60px;
  cursor: pointer;
  position: relative;

  img {
    width: 30px;
    position: absolute;
    right: -40px;
    top: 3px;
    @media ${media.mobile} {
      right: -35px;
      top: 2px;
    }
  }

  &.fill {
    background-color: #f7ac3d;
    color: #fff;
  }

  @media ${media.mobile} {
    text-align: center;
    font-size: 1em;
  }
`;

const OpenLink = styled(Link)`
  ${commonStyles}
`;

const OpenDialog = styled.div`
  ${commonStyles}
`;

const AiSolveLink = styled(Link)`
  ${commonStyles}
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: #6a0d91;
  border-color: #6a0d91;
  color: #fff;
`;
