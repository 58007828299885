import loadScript from 'simple-load-script';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

const MathJaxWithLatexContext = createContext(null);

const DEFAULT_URL =
  'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
const DEFAULT_OPTIONS = {
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
      ['\\[', '\\]'],
    ],
    macros: {
      phi: '\\varphi',
    },
    packages: { '[+]': ['text'] },
  },
};

const MathJaxWithLatexProvider = props => {
  const url = props.url || DEFAULT_URL;
  const options = props.options || DEFAULT_OPTIONS;

  const [MathJax, setMathJax] = useState(options);
  const isUpdatingMathRef = useRef(false);
  const hasUpdatingMathWaitingRef = useRef(false);

  const updateMathContent = useCallback(() => {
    if (!MathJax || typeof MathJax.typesetPromise !== 'function') return;

    if (isUpdatingMathRef.current) {
      hasUpdatingMathWaitingRef.current = true;
      return;
    }

    isUpdatingMathRef.current = true;
    MathJax.typesetPromise()
      .then(() => {
        isUpdatingMathRef.current = false;
        if (hasUpdatingMathWaitingRef.current) {
          hasUpdatingMathWaitingRef.current = false;
          updateMathContent();
        }
      })
      .catch(e => {
        isUpdatingMathRef.current = false;
        console.error(e);
      });
  }, [MathJax]);

  useEffect(() => {
    const MathJaxInWindow =
      window.MathJax && Object.keys(window.MathJax).length > 0;

    if (!MathJaxInWindow) {
      window.MathJax = MathJax;
      loadScript(url)
        .then(onLoad)
        .catch(onErr);
    } else {
      onLoad();
    }
  }, [url, MathJax]);

  function onLoad() {
    setMathJax(window.MathJax);
  }

  function onErr(err) {
    console.log(err);
  }

  return (
    <MathJaxWithLatexContext.Provider
      value={{ updateMathContent }}
      {...props}
    />
  );
};

export const useMathJaxWithLatexContext = () =>
  useContext(MathJaxWithLatexContext);

export default MathJaxWithLatexProvider;
