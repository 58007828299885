import React, { useCallback, useMemo } from 'react';
import { styled, media } from 'styles';
import { MenuItem, Select } from '@material-ui/core';
import { useUserContext } from 'providers/UserProvider';
import { ProfessorSkeleton } from './Skeletons';
import MvpBookExerciseListsBanner from 'modules/campaigns/mvp-books/banners/MvpBookExerciseListsBanner';
import { useBookEditionContext } from 'modules/books/providers/BookEditionProvider';
import { useBookExerciseTracker } from 'modules/books/hooks';

export default function ProfessorsContainer({
  selectedProfessor,
  setSelectedProfessor,
  origin,
  setOpeningType,
}) {
  const { user } = useUserContext();
  const { professors, loadingProfessors } = useBookEditionContext();
  const { trackProfessorClick } = useBookExerciseTracker();

  const onChooseProfessor = useCallback(
    (professor, from) => {
      setOpeningType('Click em professor');
      setSelectedProfessor(professor);
      trackProfessorClick({
        professor: professor,
        fromExtraProfessor: from === 'Seletor',
      });
    },
    [setOpeningType, setSelectedProfessor, trackProfessorClick]
  );

  const isProfessorSelectedFromExtraProfessors = useCallback(() => {
    if (!selectedProfessor) return false;

    return !!professors
      .slice(7)
      .some(professor => professor.id === selectedProfessor.id);
  }, [professors, selectedProfessor]);

  const getValueFromSelect = useCallback(() => {
    if (!selectedProfessor) return '';

    const isFromSelect = isProfessorSelectedFromExtraProfessors();

    return isFromSelect ? selectedProfessor : '';
  }, [isProfessorSelectedFromExtraProfessors, selectedProfessor]);

  const solvedListsTitle = useMemo(() => {
    if (!user) return 'Listas resolvidas';

    const { university } = user;

    return `Listas resolvidas ${university.gender === 'F' ? 'da' : 'do'} ${
      university.acronym
    }`;
  }, [user]);

  const shouldRenderProfessors = useMemo(() => {
    if (loadingProfessors) return true;

    return professors && professors.length > 0;
  }, [loadingProfessors, professors]);

  const subTitle = useMemo(() => {
    return shouldRenderProfessors
      ? 'Selecione seu Professor'
      : 'Envie a Primeira Lista de Cálculo da sua Faculdade!';
  }, [shouldRenderProfessors]);

  const shouldAppearProfessorSelect = useMemo(() => {
    return professors && professors.length > 7;
  }, [professors]);

  return (
    <Container className={!shouldRenderProfessors && 'full'}>
      <Title>{solvedListsTitle}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {shouldRenderProfessors ? (
        <Professors>
          {loadingProfessors ? (
            [1, 2, 3, 4, 5, 6, 7, 8].map(i => <ProfessorSkeleton key={i} />)
          ) : (
            <>
              {professors?.slice(0, 7).map((professor, index) => (
                <ProfessorButton
                  key={professor.id}
                  professor={professor}
                  onClick={() => onChooseProfessor(professor, 'Botao')}
                  selected={selectedProfessor?.id === professor.id}
                  margin={(index + 1) % 2 === 1}
                >
                  {professor.name}
                </ProfessorButton>
              ))}
              {shouldAppearProfessorSelect && (
                <CustomSelect
                  labelId="extra-professors-label"
                  value={getValueFromSelect()}
                  onChange={e => onChooseProfessor(e.target.value, 'Seletor')}
                  displayEmpty
                  renderValue={() => {
                    const professorIsFromExtraProfessors = isProfessorSelectedFromExtraProfessors();
                    if (!professorIsFromExtraProfessors) {
                      return <span>Outros professores</span>;
                    }
                    return (
                      <span style={{ color: '#14B9A6' }}>
                        {selectedProfessor.name}
                      </span>
                    );
                  }}
                >
                  <MenuItem value="" style={{ display: 'none' }}>
                    Outros professores
                  </MenuItem>
                  {professors?.slice(7).map(professor => (
                    <MenuItem key={professor.id} value={professor}>
                      {professor.name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </>
          )}
        </Professors>
      ) : (
        <MvpBookExerciseListsBanner origin={origin} />
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 235px;

  &.full {
    width: 100%;
  }

  @media ${media.mobile} {
    min-height: 0;
  }
`;

const Title = styled.h1`
  color: #14b9a6;
  font-weight: bold;
  font-size: 1.8em;
  margin-bottom: 5px;
`;

const SubTitle = styled.h2`
  font-size: 1.35em;
  color: #14b9a6;
`;

const Professors = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 492px;
  min-width: 460px;

  @media ${media.mobile} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const ProfessorButton = styled.button`
  width: 230px;
  height: 27px;
  border: ${({ selected }) => (selected ? '0' : '1px solid #636363')};
  border-radius: 15px;
  padding: 4px 15px;
  margin: 10px 16px 5px 0;
  cursor: ${({ selected }) => (selected ? 'normal' : 'pointer')};
  font-size: 17px;
  background-color: ${({ selected }) => (selected ? '#14B9A6' : '#fff')};
  color: ${({ selected }) => (selected ? '#fff' : '#636363')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background-color: ${({ selected }) => (selected ? '#14B9A6' : '#e0e0e0')};
  }

  @media ${media.mobile} {
    width: 47% !important;
    padding: 5px 7px;
    margin: 7px 16px 5px 0;
    margin-right: ${({ margin }) => (margin ? '16px' : 0)};
    font-size: 15px;
    max-width: 170px;
  }
`;

const CustomSelect = styled(Select)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 230px;
    height: 27px;
    text-decoration: underline;
    color: #636363;
    font-size: 18.5px;
    line-height: 20px;
    text-underline-offset: 5px;
    cursor: pointer;
    margin-top: 11px;

    // Remove default border and background
    .MuiSelect-select {
      background-color: transparent;
      padding: 0;
    }
    // Hide the dropdown icon
    .MuiSelect-icon {
      display: none;
    }

    &.MuiInput-underline:before {
      border-bottom: 0px solid transparent;
      display: none;
    }

    &.MuiInput-underline:after {
      border-bottom: 0px solid transparent;
      display: none;
    }

    @media ${media.mobile} {
      width: 170px;
    }
  }
`;
