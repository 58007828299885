import React, { useCallback } from 'react';
import {
  ChooseBookStep,
  ChooseListTypeStep,
  FifthStep,
  FinalStep,
  FourthStep,
  ListInfoStep,
  LoadingStep,
  ManualInput,
  UploadListWithStatementStep,
} from '../components';
import SuccessListWithStatementStep from '../components/SuccessListWithStatementStep';
import { useUploadDialogContext } from '../providers/UploadDialogProvider';

export default function useStepNavigation() {
  const { step, loading, statementList } = useUploadDialogContext();

  const getComponentByStep = useCallback(
    origin => {
      if (step === 1) return <ChooseListTypeStep />;
      if (step === 2) return <ListInfoStep origin={origin} />;

      // STEPS LISTA DE SELEÇÃO DO LIVRO
      if (!statementList) {
        if (step === 3) return <ChooseBookStep />;
        if (step === 4) return <FourthStep />;
        if (step === 5) return <FifthStep />;
        if (step === 6) return <ManualInput />;
        if (step === 7) return <FinalStep />;
      }

      // STEPS LISTA DE ENUNCIADO
      if (statementList) {
        if (step === 3) return <UploadListWithStatementStep />;
        if (step === 4) return <SuccessListWithStatementStep />;
      }

      if (step === 'loading') {
        return <LoadingStep loading={loading} />;
      }
    },
    [loading, statementList, step]
  );

  return {
    getComponentByStep,
  };
}
