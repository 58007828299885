import React, { useCallback, useEffect, useState } from 'react';
import { styled } from 'styles';
import { LoadingBar } from 'modules/shared/components/generic/loaders';
import { LoginButtonsContainer } from 'modules/shared/components/login';
import { useUserContext } from 'providers/UserProvider';
import { colors } from 'styles';
import { useUploadStatementListPageTracker } from 'modules/mvp/hooks/useUploadStatementListPageTracker';

export function MainDialog({ isListPending, list }) {
  const { user } = useUserContext();

  const [loadingProgress, setLoadingProgress] = useState(0);
  const { trackViewThankYouPage } = useUploadStatementListPageTracker();

  const getInitialLoadingProgress = useCallback(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const initialProgress = searchParams.get('loading_progress');
    if (!initialProgress) {
      return 5;
    }

    return parseInt(initialProgress);
  }, []);

  useEffect(() => {
    if (!list) return;

    trackViewThankYouPage({ listId: list.id, wasLogged: !!user });
  }, [list, trackViewThankYouPage, user]);

  return (
    <Container>
      <Content onClick={e => e.stopPropagation()}>
        {isListPending && (
          <LoadingContainer shouldDeslocateTop={!!user}>
            <Title>
              Estamos organizando a sua lista resolvida passo a passo !
            </Title>
            <SubTitle>Demora aproximadamente uns 40s pra ficar pronta</SubTitle>
            <LoadingBar
              onProgressChange={setLoadingProgress}
              initialProgress={() => getInitialLoadingProgress()}
            />
          </LoadingContainer>
        )}
        {!user && (
          <>
            <LoginText>
              {isListPending
                ? `Enquanto Isso, complete o seu cadastro (grátis):`
                : `Agora só falta completar o seu cadastro (gratis) para acessar:`}
            </LoginText>
            <LoginButtonsContainer
              origin="lista de enunciado"
              config={{
                addParamToOrigin: [
                  {
                    param: 'skip_profile_complete',
                    value: true,
                  },
                  {
                    param: 'is_from_mkt_list',
                    value: true,
                  },
                  {
                    param: 'loading_progress',
                    value: `${loadingProgress}`,
                  },
                ],
              }}
            />
          </>
        )}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoginText = styled.div`
  color: ${colors.mainOrange};
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Content = styled.div`
  position: relative;
  max-width: 95%;
  width: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #575757;
  border-radius: 36px;
  padding: 35px 40px 25px 40px;
  box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.25);
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 35px;
  flex-direction: column;
  position: ${({ shouldDeslocateTop }) =>
    shouldDeslocateTop ? 'static' : 'absolute'};
  top: ${({ shouldDeslocateTop }) => (shouldDeslocateTop ? '0px' : '-160px')};
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.8rem;
  user-select: none;
`;

const SubTitle = styled.p`
  color: ${colors.darkGray};
  margin-top: 10px;
`;
