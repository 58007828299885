import React, { useCallback, useEffect, useRef, useState } from 'react';
import { UploadArea } from '../components/UploadStatementList/UploadArea';
import { colors, styled, media } from 'styles';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import { useToast } from 'modules/shared/hooks';
import { useHistory } from 'react-router-dom';
import gitDesktop from './gifDesktop.gif';
import gifMobile from './gifMobile.gif';
import { raLogo } from 'images/main';
import { useUploadStatementListPageTracker } from '../hooks/useUploadStatementListPageTracker';

export function UploadStatementListPage() {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { sendPostFileRequest } = useContentRestRequest();
  const { errorToast } = useToast();
  const history = useHistory();
  const {
    trackViewPage,
    trackUploadList,
  } = useUploadStatementListPageTracker();

  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOSWebView =
      /iPhone|iPod|iPad/i.test(userAgent) &&
      !window.MSStream &&
      (window.navigator.standalone || /WebView/.test(userAgent));
    const isAndroidWebView = /wv|Android.*Version\/[0-9]+/.test(userAgent);
    return isIOSWebView || isAndroidWebView;
  };

  const openInBrowser = useCallback(url => {
    if (isWebView()) {
      if (/Android/i.test(navigator.userAgent)) {
        // Intent genérico para Android
        const intentUrl = `intent://${url.replace(
          /^https?:\/\//,
          ''
        )}#Intent;scheme=https;action=android.intent.action.VIEW;end`;
        console.log({ intentUrl });
        //window.location = intentUrl;
      } else {
        console.log({ url });
        // Para outros dispositivos, apenas redireciona para uma nova aba
        //window.open(url, '_blank');
      }
    }
  }, []);

  useEffect(() => {
    openInBrowser(window.location.href);
  }, [openInBrowser]);

  const inputRef = useRef();

  useEffect(() => {
    const { search } = location;
    const searchParams = new URLSearchParams(search);

    const cameFromRejectedList = !!searchParams.get('list_rejected');
    trackViewPage(cameFromRejectedList, isWebView());
  }, [trackViewPage]);

  const sendList = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await sendPostFileRequest(
        '/mvp/list-with-statements-incomplete',
        file
      );

      if (response.data.listId) {
        trackUploadList(file?.type);

        const currentQuery = new URLSearchParams(location.search).toString();
        const newUrl = `/lista-de-enunciado/${response.data.listId}${
          currentQuery ? `?${currentQuery}` : ''
        }`;

        history.push(newUrl);
      }
    } catch (e) {
      errorToast(
        'Erro durante o envio da lista, por favor tente novamente mais tarde'
      );
    } finally {
      setLoading(false);
    }
  }, [
    errorToast,
    file,
    history,
    loading,
    sendPostFileRequest,
    trackUploadList,
  ]);

  const handleInputChange = useCallback(event => {
    const selectedFile = event.target.files[0]; // Obtém o arquivo selecionado
    if (selectedFile) {
      setFile(selectedFile); // Seta o estado com o arquivo
      console.log('Arquivo selecionado:', selectedFile);
    }
  }, []);

  const renderMobilePage = useCallback(() => {
    return (
      <Container>
        <Header />
        <UploadSection>
          <Title>Sua Lista de exercícios</Title>
          <SmallTitle>resolvida em 45 segundos</SmallTitle>
          <GifContainer>
            <InstructionGif src={gifMobile} />
          </GifContainer>
          <Bullets>
            <Bullet>Envie o PDF ou print da sua lista</Bullet>
            <Bullet>Receba sua lista resolvida passo a passo</Bullet>
            <Bullet>Questões revisadas pelo Responde Aí</Bullet>
          </Bullets>

          {file?.name}

          <input
            ref={inputRef}
            type="file"
            onChange={e => {
              handleInputChange(e);
            }}
            accept="image/jpeg,image/png,image/jpg,application/pdf"
          ></input>

          <UploadButton
            onClick={() => {
              if (!file && inputRef?.current) {
                inputRef.current.click();
                return;
              }

              sendList();
            }}
          >
            {loading ? 'Enviando...' : 'Enviar Lista'}
          </UploadButton>
        </UploadSection>
      </Container>
    );
  }, [file, handleInputChange, loading, sendList]);

  if (media.isMobile()) return renderMobilePage();

  return (
    <Container>
      <DesktopHeader>
        <img src={raLogo} />
      </DesktopHeader>
      <UploadSection>
        <Title>Sua Lista de exercícios</Title>
        <SmallTitle>resolvida em 45 segundos</SmallTitle>
        <SubTitle>
          Envie o PDF ou print da sua lista e <br />
          receba instanteneamente cada <br />
          exercício resolvido passo a passo
        </SubTitle>
        <UploadInputContainer>
          {file ? (
            <>
              <FileConfirmation filename={file?.name}>
                {file.name}
              </FileConfirmation>
              <UploadButton onClick={sendList}>
                {loading ? 'Enviando...' : 'Enviar Lista'}
              </UploadButton>
            </>
          ) : (
            <UploadArea setFile={setFile} currentFile={file} />
          )}
        </UploadInputContainer>
      </UploadSection>
      <ExplanationSection>
        <GifContainer>
          <InstructionGif src={gitDesktop} />
        </GifContainer>
        <Bullets>
          <Bullet>Envie o PDF ou print da sua lista</Bullet>
          <Bullet>Receba sua lista resolvida passo a passo</Bullet>
          <Bullet>Questões revisadas pelo Responde Aí</Bullet>
        </Bullets>
      </ExplanationSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  /* padding: 35px 70px 0 70px; */
  flex-wrap: wrap;
  justify-content: center;
  @media ${media.mobile} {
    padding: 0;
  }
`;

const DesktopHeader = styled.div`
  height: 50px;
  padding: 5px;
  background-color: white;
  width: 100%;
  display: flex;
  box-shadow: 2px 1px 3px 2px rgba(0, 0, 0, 0.25);
  img {
    margin-left: 45px;
  }
`;

const Header = styled.div`
  height: 7vh;
  max-height: 70px;
  width: 100%;
  background-color: ${colors.mainOrange};
`;

const UploadSection = styled.div`
  margin-top: 40px;
  padding: 15px;
  min-width: 122px;
  input {
    display: none;
  }

  @media ${media.mobile} {
    margin: 0;
    text-align: center;
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    min-height: 93vh;
    box-sizing: border-box;

    justify-content: center;
  }
`;

const Title = styled.div`
  font-size: 3.2em;
  text-transform: uppercase;
  color: ${colors.mainOrange};
  font-weight: bold;

  @media ${media.mobile} {
    font-size: 1.5em;
    margin-top: 7px;
    text-shadow: 2px 2px 4px rgba(174, 172, 172, 0.45);
  }
`;

const SmallTitle = styled.div`
  font-size: 2.6em;
  margin-top: 10px;
  text-transform: uppercase;
  color: ${colors.mainOrange};
  font-weight: bold;
  @media ${media.mobile} {
    font-size: 1.5em;
    text-shadow: 2px 2px 4px rgba(174, 172, 172, 0.45);
    margin-bottom: 25px;
    margin-top: 5px;
  }
`;

const SubTitle = styled.div`
  font-size: 1.7em;
  margin: 55px 0 80px 0;
  color: #343434;
`;

const UploadInputContainer = styled.div`
  border: 1px solid #575757;
  border-radius: 36px;
  padding: 35px 40px 25px 40px;
  box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.25);
  max-width: 700px;
`;

const UploadButton = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
  padding: 12px;
  border-radius: 12px;
  background-color: ${colors.mainOrange};
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease; /* Suaviza a transição */
  &:hover {
    background-color: #eba33b;
  }
`;

const ExplanationSection = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;
const GifContainer = styled.div`
  padding: 20px;
  border: 1px solid ${colors.mainOrange};
  border-radius: 20px;
  margin-bottom: 45px;
  max-width: 520px;
  @media ${media.mobile} {
    max-width: 70%;
    height: 50vh;
    max-height: 450px;
    margin: 0 auto 0 auto;
    margin-bottom: 30px;
    border: none;
    box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
`;

const InstructionGif = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const Bullets = styled.div`
  display: flex;
  flex-direction: column;
`;
const Bullet = styled.div`
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  font-size: 1.4em;
  display: flex;
  align-items: center;
  color: #343434;

  @media ${media.mobile} {
    font-size: 0.9em;
    margin-bottom: 7px;
    padding-left: 22px;
  }

  &::before {
    content: '✔'; /* Ícone de checkmark */
    position: absolute;
    left: 0;
    top: 2px;
    color: white; /* Cor do ícone */
    font-size: 18px;
    height: 21px;
    width: 21px;
    text-align: center;
    border-radius: 50%;
    background-color: ${colors.mainOrange};
    @media ${media.mobile} {
      font-size: 16px;
      height: 18px;
      width: 18px;
      background-color: white;
      color: gray;
      top: -1px;
    }
  }
`;
const FileConfirmation = styled.div``;
