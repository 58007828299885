import { useCookies } from 'modules/shared/hooks';
import { useCallback } from 'react';

export default function useAutommaticalyOpen() {
  const [, setCookie] = useCookies(['new_discipline_viewer']);

  const setCookieViewer = useCallback(
    cookieName => {
      const configOptions = {
        expires: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000), // one year from now
      };

      setCookie(cookieName, true, configOptions);
    },
    [setCookie]
  );

  const removeQueryParamOnOpenDialog = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('show_upload');

    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${urlParams.toString()}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }, []);

  return {
    setCookieViewer,
    removeQueryParamOnOpenDialog,
  };
}
