export const EXAM_NAMES = [
  'Primeira prova',
  'Segunda prova',
  'Terceira prova',
  'Outras provas',
];

export const themesIdsToShow = [
  1,
  2,
  3,
  4,
  6,
  7,
  8,
  10,
  12,
  15,
  16,
  17,
  18,
  19,
];

export const BookEditionsIdsToShow = [
  2,
  26,
  18,
  34,
  29,
  7,
  20,
  30,
  13,
  14,
  55,
  172,
  10,
  40,
  53,
  207,
  58,
  91,
  33,
  5,
  19,
  31,
  1,
  21,
  205,
  41,
  6,
  25,
  49,
  67,
  45,
  24,
  52,
  35,
  83,
  16,
  8,
  27,
  90,
];

export const booksToShow = [
  1,
  2,
  7,
  10,
  37,
  36,
  9,
  35,
  40,
  4,
  3,
  12,
  31,
  49,
  27,
  20,
  34,
  21,
  66,
  16,
  15,
];

export const bookEditionWithEndChapterExercise = [
  172,
  10,
  40,
  53,
  207,
  58,
  91,
  33,
  5,
  19,
  31,
  1,
  21,
  205,
  41,
  6,
  25,
  45,
  24,
  52,
  35,
  83,
  16,
  8,
  27,
  90,
];

export const MapBookToAuthor = {
  1: {
    author: 'James Stewart',
    bookName: 'Cálculo Volume 1',
  },
  2: {
    author: 'James Stewart',
    bookName: 'Cálculo Volume 2',
  },
  7: {
    author: 'Guidorizzi',
    bookName: 'Cálculo Volume 1',
  },
  10: {
    author: 'Guidorizzi',
    bookName: 'Cálculo Volume 2',
  },
  37: {
    author: 'Boyce',
    bookName: 'Equações Diferenciais',
  },
  9: {
    author: 'Young and Freedman',
    bookName: 'Física 1 Mecânica',
  },
  35: {
    author: 'Young and Freedman',
    bookName: 'Física 2 Termodinâmica e Ondas',
  },
  40: {
    author: 'Young and Freedman',
    bookName: 'Física 3 Eletromagnetismo',
  },
  4: {
    author: 'Halliday',
    bookName: 'Física Volume 1',
  },
  3: {
    author: 'Halliday',
    bookName: 'Física Volume 2',
  },
  12: {
    author: 'Halliday',
    bookName: 'Física Volume 3',
  },
  31: {
    author: 'Anton',
    bookName: 'Álgebra Linear',
  },
  49: {
    author: 'Paulo Winterle',
    bookName: 'Vetores e Geometria Analítica',
  },
  27: {
    author: 'Sadiku e Alexander',
    bookName: 'Fundamentos de Circuitos Elétricos',
  },
  20: {
    author: 'Atkins & Jones',
    bookName: 'Princípios de Química',
  },
  34: {
    author: 'Fox, McDonald & Pritchard',
    bookName: 'Introdução à Mecânica dos Fluidos',
  },
  21: {
    author: 'Beer',
    bookName: 'Estática',
  },
  66: {
    author: 'Beer',
    bookName: 'Dinâmica',
  },
  15: {
    author: 'Hibbeler',
    bookName: 'Estática',
  },
  16: {
    author: 'Hibbeler',
    bookName: 'Dinâmica',
  },
};
