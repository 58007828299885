import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Header } from 'modules/shared/components/header';
import Footer from 'modules/shared/components/Footer';
import { styled } from 'styles';
import profileRoutes from 'modules/profile/routes';
import mainRoutes from 'modules/main/routes';
import booksRoutes from 'modules/books/routes';
import summariesRoutes from 'modules/summaries/routes';
import lecturesRoutes from 'modules/lectures/routes';
import learningPathRoutes from 'modules/learning-path/routes';
import guidesRoutes from 'modules/guides/routes';
import campaignLectureRoutes from 'modules/campaigns/campaign-lecture/routes';
import publicLecturesFromCampaignsRoutes from 'modules/campaigns/icb-lecture/routes';
import bookTrialRoutes from 'modules/campaigns/book-trial/routes';
import workspaceRoutes from 'modules/workspace/routes';
import bypassContentRoutes from 'modules/bypass-content/routes';
import WarningBanner from 'modules/banner/warning-banner/WarningBanner';
import { useLocation } from 'react-router-dom';
import { useProfileComplete } from 'modules/profile/hooks';
import useRenderGlobalDialog from 'modules/shared/hooks/useRenderGlobalDialog';
import mvpRoutes from 'modules/mvp/routes';

export default function LoggedRouter() {
  const location = useLocation();
  const history = useHistory();
  const { shouldRedirectToProfileComplete } = useProfileComplete();

  const shouldShowFooter = useMemo(() => {
    return (
      !location.pathname.includes('/icb/aulao-intensivao') &&
      !location.pathname.includes('/aprender') &&
      !location.pathname.includes('/desafio') &&
      !location.pathname.includes('/resolver-lista')
    );
  }, [location.pathname]);

  useRenderGlobalDialog();

  useEffect(() => {
    if (shouldRedirectToProfileComplete())
      history.push(`/profile-complete?redirect=${location.pathname}`);
  }, [history, location.pathname, shouldRedirectToProfileComplete]);

  return (
    <>
      <WarningBanner />
      <Header />
      <MainContainer>
        <Switch>
          {mainRoutes}
          {profileRoutes}
          {summariesRoutes}
          {publicLecturesFromCampaignsRoutes}
          {booksRoutes}
          {guidesRoutes}
          {bookTrialRoutes}
          {workspaceRoutes}
          {lecturesRoutes}
          {campaignLectureRoutes}
          {bypassContentRoutes}
          {learningPathRoutes}
          {mvpRoutes}
          <Route
            path="/planos"
            component={() => {
              window.location.href = `${process.env.REACT_APP_RA_WEB_SSR_URL}/planos`;
              return null;
            }}
          />
          <Route
            path="/"
            component={() => {
              history.push('/materias');
              return null;
            }}
          />
        </Switch>
      </MainContainer>
      <Footer shouldShowFooter={shouldShowFooter} />
    </>
  );
}

const MainContainer = styled.div`
  min-height: 100vh;
`;
