import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { media, styled } from 'styles';

import { MathJaxWithLatexProvider } from 'providers';
import { useContentQueryImmutable } from 'modules/shared/hooks/requests';
import { QuestionContent, QuestionTopBar } from '../components/shared';
import {
  AiResponse,
  Statement,
} from '../components/ListWithStatementQuestionSolvingByAiPage';

export function ListWithStatementQuestionSolvingByAiPage() {
  const { listId, questionId } = useParams();

  const { data: question } = useContentQueryImmutable(
    `/mvp/lists-with-statements/questions/${questionId}`
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MathJaxWithLatexProvider>
      <Container>
        <QuestionTopBar listId={listId} style={{ marginTop: '20px' }} />

        <QuestionContent>
          <Statement question={question} />
          <AiResponse question={question} />
        </QuestionContent>
      </Container>
    </MathJaxWithLatexProvider>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;
