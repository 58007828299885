import { useTracker, useUTM } from 'modules/shared/hooks';
import { useCallback, useMemo } from 'react';
import { media } from 'styles';

export function useUploadStatementListPageTracker() {
  const { trackEvent } = useTracker();
  const { getUTMsByLocation } = useUTM();

  const defaultProperties = useMemo(() => {
    return {
      Plataforma: media.isMobile() ? 'Mobile' : 'Desktop',
      Versão: '1',
      ...getUTMsByLocation(window.location),
    };
  }, [getUTMsByLocation]);

  const trackViewPage = useCallback(
    (cameFromRejectedList, isFromWebView) => {
      trackEvent('Marketing Listas: Visualizou LP de upload', {
        ...defaultProperties,
        'Toast de lista recusada?': cameFromRejectedList ? 'Sim' : 'Não',
        'Abriu via Webview': isFromWebView ? 'Sim' : 'Não',
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackUploadList = useCallback(
    fileType => {
      trackEvent('Marketing Listas: Fez upload de arquivo', {
        ...defaultProperties,
        'Tipo de arquivo': fileType,
      });
    },
    [trackEvent, defaultProperties]
  );

  const trackViewThankYouPage = useCallback(
    ({ listId, wasLogged }) => {
      trackEvent('Marketing Listas: Viu dialog de loading/login', {
        ...defaultProperties,
        'ID da lista': listId,
        'Caiu logado': wasLogged ? 'Sim' : 'Não',
      });
    },
    [trackEvent, defaultProperties]
  );

  return {
    trackViewPage,
    trackUploadList,
    trackViewThankYouPage,
  };
}
