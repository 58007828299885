import React, { useCallback, useEffect, useState } from 'react';
import { styled, colors } from 'styles';

export default function LoadingBar({ initialProgress, onProgressChange }) {
  const [progress, setProgress] = useState(initialProgress || 5);

  const increaseProgress = useCallback(() => {
    setProgress(prev => {
      if (prev < 100) {
        return prev + 1;
      }
      return prev;
    });

    onProgressChange(progress);
  }, [onProgressChange, progress]);

  useEffect(() => {
    let interval;

    if (progress < 55) {
      interval = setInterval(() => {
        increaseProgress();
      }, 900);
    } else if (progress < 80) {
      interval = setInterval(() => {
        increaseProgress();
      }, 1350);
    } else {
      interval = setInterval(() => {
        increaseProgress();
      }, 2000);
    }

    return () => clearInterval(interval);
  }, [increaseProgress, progress]);

  return (
    <LoadingContainer>
      <ProgressBar progress={progress} />
      <p>{`${progress} %`}</p>
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  width: 300px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid ${colors.darkGray};
  margin: 20px 0;
  padding: 3px;
  p {
    margin-top: 10px;
    font-weight: bold;
    text-align: right;
    font-size: 17px;
  }
`;

const ProgressBar = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;
  border-radius: 15px;
  background-color: #06a54f;
`;
