import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useToast } from 'modules/shared/hooks';
import { styled, colors, media } from 'styles';

export function UploadArea({ setFile, currentFile }) {
  const { errorToast } = useToast();

  const isFileValid = useCallback(
    async acceptedFiles => {
      if (acceptedFiles.length === 0) {
        errorToast('Você só pode fazer o upload dos formatos permitidos');
        return false;
      }

      if (acceptedFiles.length > 1) {
        errorToast('Você só pode fazer o upload de um único arquivo.');
        return false;
      }

      return true;
    },
    [errorToast]
  );

  const onDrop = useCallback(
    async acceptedFiles => {
      const isValid = await isFileValid(acceptedFiles);
      if (!isValid) return;

      const file = acceptedFiles[0];

      setFile(file);
    },
    [isFileValid, setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
      'application/pdf': [],
    },
    onDrop,
  });

  return (
    <>
      <StyledUploadArea {...getRootProps()}>
        <Container>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="release-text">Solte!</p>
          ) : (
            <InsideContainer>
              <p>Envie sua Lista de Exercícios </p>
              <UploadButton>Buscar Arquivo</UploadButton>
            </InsideContainer>
          )}
        </Container>
        {!currentFile && <SendListButton>Enviar Lista</SendListButton>}
      </StyledUploadArea>
    </>
  );
}

const StyledUploadArea = styled.div`
  .release-text {
    margin-top: 50px;
    color: ${colors.mainOrange};
    font-size: 20px;
  }

  @media ${media.mobile} {
    margin-bottom: 15px;
    min-height: initial;
    width: 80%;
    background: #f9faf9;
    display: initial;
  }
`;

const Container = styled.div`
  padding: 5px 5px 5px 0px;
  border-bottom: 1px solid #575757;
`;

const InsideContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  p {
    color: #989898;
    font-size: 1.55em;
  }
`;

const UploadButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1em;
  background: linear-gradient(to bottom, #f5f5f5 0%, #d9d9d9 100%);
  border: 1px solid #a9a9a9;
  color: black;
  padding: 5px 10px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
`;

const SendListButton = styled.div`
  margin-top: 20px;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
  padding: 12px;
  border-radius: 12px;
  background-color: ${colors.mainOrange};
  cursor: pointer;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease; /* Suaviza a transição */
  &:hover {
    background-color: #eba33b;
  }
`;
