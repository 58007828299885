import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { media, styled } from 'styles';
import {
  CheckSearchResult,
  CheckedSearchResult,
  PageSkeleton,
  SearchLogs,
  SearchResult,
} from '../components/ListWithStatementQuestion';
import { QuestionTopBar, QuestionContent } from '../components/shared';
import { MathJaxWithLatexProvider } from 'providers';
import { useContentQuery } from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';
import { useDialogContext } from 'providers/DialogProvider';
import { LoginDialog } from 'modules/shared/dialogs';
import { PlansDialog } from 'modules/campaigns/checkout-light/dialogs';
import { useQuestions } from '../hooks';

export function ListWithStatementQuestionPage() {
  const { user } = useUserContext();
  const { setDialog } = useDialogContext();
  const { listId, questionId } = useParams();
  const {
    questionsWithBlocked,
    getNextExercise,
    getPreviousExercise,
  } = useQuestions();

  const { data: question, mutate: refetchQuestion } = useContentQuery(
    `/mvp/lists-with-statements/questions/${questionId}`
  );
  const { data: rawList, mutate: refetchList } = useContentQuery(
    `/mvp/lists-with-statements/${listId}`
  );

  const [showFoundQuestionMessage, setShowFoundQuestionMessage] = useState(
    false
  );

  const list = useMemo(() => {
    if (!rawList?.questions) return null;
    const enrichedQuestions = questionsWithBlocked(rawList);
    return {
      ...rawList,
      questions: enrichedQuestions,
    };
  }, [rawList, questionsWithBlocked]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [question]);

  useEffect(() => {
    // TODO: create a global render dialog hook for this page
    if (!user) setDialog(<LoginDialog />);
  }, [user, setDialog]);

  const isQuestionBlocked = useMemo(() => {
    if (!list?.questions) return false;

    const currentQuestion = list.questions.find(question => {
      return question.id == questionId;
    });
    if (!currentQuestion?.blocked) return false;
    return user && !user?.hasAccess;
  }, [list, questionId, user]);

  useEffect(() => {
    if (isQuestionBlocked)
      setDialog(
        <PlansDialog source={'Lista de Enunciado'} removeClose={true} />
      );
  }, [isQuestionBlocked, setDialog]);

  const onCheckResult = useCallback(
    found => {
      refetchQuestion();
      refetchList();

      if (found) setShowFoundQuestionMessage(true);
    },
    [refetchQuestion, refetchList]
  );

  const showCheckSearchResult = useCallback(() => {
    return (
      question.exercise &&
      !question.exerciseWasSelectedByUser &&
      question?.exerciseType !== 'MvpExercise'
    );
  }, [question]);

  const showChekedSearchResult = useCallback(() => {
    return (
      question.exercise &&
      (question.exerciseWasSelectedByUser ||
        question.exerciseType === 'MvpExercise')
    );
  }, [question]);

  const showSearchLog = useCallback(() => {
    return !question.exercise;
  }, [question]);

  const nexQuestion = useMemo(() => {
    if (!question || !list) return;
    return getNextExercise(list, question.id);
  }, [getNextExercise, list, question]);

  const prevQuestion = useMemo(() => {
    if (!question || !list) return;
    return getPreviousExercise(list, question.id);
  }, [getPreviousExercise, list, question]);

  if (!question) return <PageSkeleton />;

  return (
    <MathJaxWithLatexProvider>
      <Container>
        <QuestionTopBar
          listId={listId}
          topic={question.exercise?.topic}
          style={{ marginTop: '20px' }}
        />
        <QuestionContent>
          {showCheckSearchResult() && (
            <>
              <CheckSearchResult
                question={question}
                onCheckResult={onCheckResult}
              />
              <SearchResult question={question} />
            </>
          )}
          {showFoundQuestionMessage && (
            <ShowFoundQuestionMessage>
              Uhuuul! 🎉 Agora é só partir pra resolução!
            </ShowFoundQuestionMessage>
          )}
          {showChekedSearchResult() && (
            <CheckedSearchResult question={question} />
          )}
          {showSearchLog() && (
            <SearchLogs question={question} onCheckResult={onCheckResult} />
          )}
        </QuestionContent>
        <QuestionNav>
          {prevQuestion && (
            <NavButton
              className="prev"
              to={`/lista-de-enunciado/${listId}/questao/${prevQuestion.id}`}
            >
              Anterior
            </NavButton>
          )}
          {nexQuestion && (
            <NavButton
              className="next"
              to={`/lista-de-enunciado/${listId}/questao/${nexQuestion.id}`}
            >
              Ir para o próximo exercício
            </NavButton>
          )}
        </QuestionNav>
      </Container>
    </MathJaxWithLatexProvider>
  );
}

const Container = styled.div`
  width: 1080px;
  max-width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media ${media.mobile} {
    margin-top: 80px;
  }
`;

const ShowFoundQuestionMessage = styled.h1`
  margin: 25px auto;
  text-align: center;
  color: #212121;
  font-size: 1.8em;
`;

const QuestionNav = styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid rgb(221, 221, 221);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 13px 13px;
  line-height: 50px;
  font-weight: bold;
  cursor: pointer;
`;

const NavButton = styled(Link)`
  flex: 4 1 0%;
  text-align: center;
  color: rgb(0, 184, 214);
  border-left: 1px solid rgb(221, 221, 221);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 1.2rem;
  transition: background 0.2s ease-in-out 0s;
  border-radius: 0px 0px 5px;
  text-decoration: none;

  &.next {
    @media ${media.mobile} {
      flex: 3 1 0%;
    }
    &:hover {
      background: rgb(0, 184, 214);
      color: white;
    }
  }

  &.prev {
    flex: 1 1 0%;
    color: rgb(247, 172, 60);

    &:hover {
      background: rgb(247, 172, 60);
      color: white;
    }
  }
`;
