import React, { useEffect, useMemo, useState } from 'react';

import { styled } from 'styles';
import dexterAiImg from 'images/mvp/dexter-ai.png';
import { useContentRestRequest } from 'modules/shared/hooks/requests';
import MathjaxContentWithLatex from 'modules/shared/components/generic/MathjaxContentWithLatex';
import { LinearProgress, Skeleton } from '@mui/material';
import { useHistory } from 'react-router-dom';

export function AiResponse({ question }) {
  const { sendPostStreamRequest } = useContentRestRequest();
  const [solution, setSolution] = useState('');
  const [finished, setFinished] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!question) return;
    if (question.exercise) {
      history.push(
        `/lista-de-enunciado/${question.mvpListWithStatementId}/questao/${question.id}`
      );
      return;
    }

    sendPostStreamRequest({
      url: `/mvp/lists-with-statements/questions/${question.id}/generate_response`,
      onStream: chunk => {
        setSolution(prevSolution => prevSolution + chunk);
      },
      onEnd: () => {
        setFinished(true);
      },
      onError: error => {
        console.error(error);
      },
    });
  }, [sendPostStreamRequest, question, history]);

  const { steps, answer } = useMemo(() => {
    return parseSteps(solution);
  }, [solution]);

  if (!question) return <CustomSkeleton variant="text" height={300} />;

  return (
    <>
      <SolutionTitle>Resolução passo a passo</SolutionTitle>
      <Container>
        <DexterImage src={dexterAiImg} />
        <Content>
          {steps.map((step, index) => (
            <>
              <StepTitle>Passo {index + 1}:</StepTitle>
              {step && <LatexContent text={step} isLatex={true} />}
            </>
          ))}

          {answer && (
            <>
              <StepTitle>Resposta:</StepTitle>
              <LatexContent text={answer} isLatex={true} />
            </>
          )}

          {!finished && <LinearProgress color="inherit" />}
        </Content>
      </Container>
    </>
  );
}

function parseSteps(text) {
  const regex = /#\s*(passo|Passo|resposta|Resposta)[^\n]*\n/g;
  const parts = text.split(regex).filter(Boolean);

  const steps = [];
  let answer = null;

  for (let i = 0; i < parts.length; i += 2) {
    const label = parts[i].toLowerCase();
    const content = parts[i + 1]?.trim();

    if (label.includes('passo')) {
      steps.push(content);
    } else if (label.includes('resposta')) {
      answer = content;
    }
  }

  return { steps, answer };
}

const Container = styled.div`
  display: flex;
  padding: 20px;
`;

const SolutionTitle = styled.h1`
  width: fit-content;
  max-width: 95%;
  margin: auto;
  font-size: 1.5em;
  font-weight: bold;
  padding: 15px 50px;
  background-color: #6a0d91;
  color: #fff;
  border-radius: 25px;
`;

const DexterImage = styled.img`
  width: 50px;
  height: 50px;
`;

const Content = styled.div`
  margin-top: 10px;
  margin-left: 20px;
`;

const StepTitle = styled.h1`
  color: #6a0d91;
  font-weight: bold;
  font-size: 1.4em;
  text-decoration: underline;
  text-align: left;
`;

const LatexContent = styled(MathjaxContentWithLatex)`
  color: #5d5d5d;
  margin-top: 15px;
`;

const CustomSkeleton = styled(Skeleton)`
  &.MuiSkeleton-root {
    transform: none;
  }
`;
